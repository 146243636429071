import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import HomeView from "@/views/HomeView.vue"
import FileListView from "@/views/FileListView.vue"
import FileAddView from "@/views/FileAddView.vue"
import FileEditView from "@/views/FileEditView.vue"
import FileShowView from "@/views/FileShowView.vue"
import StudentListView from "@/views/StudentListView.vue"
import StudentAddView from "@/views/StudentAddView.vue"
import StudentEditView from "@/views/StudentEditView.vue"
import PasswordView from "@/views/PasswordView.vue"
import HistoryView from "@/views/HistoryView.vue"
import CocoStudyView from "@/views/CocoStudyView.vue"
import TeacherCocoStudyView from "@/views/TeacherCocoStudyView.vue"
import CocoStudyMaterialView from "@/views/CocoStudyMaterialView.vue"
import MaterialListView from "@/views/MaterialListView.vue"
import NotifyView from "@/views/NotifyView.vue"
import NotifyTemplatesView from "@/views/NotifyTemplatesView.vue"
import NotifyGroupsView from "@/views/NotifyGroupsView.vue"
import LineLinkView from "@/views/LineLinkView.vue"
import LineLinkLoginView from "@/views/LineLinkLoginView.vue"
import LineLinkSiblingLoginView from "@/views/LineLinkSiblingLoginView.vue"
import LineLinkSiblingView from "@/views/LineLinkSiblingView.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/files",
    name: "fileListView",
    component: FileListView,
  },
  {
    path: "/file/add",
    name: "fileAddView",
    component: FileAddView,
  },
  {
    path: "/file/show/:id",
    name: "fileShowView",
    component: FileShowView,
  },
  {
    path: "/file/:id/edit",
    name: "fileEditView",
    component: FileEditView,
  },
  {
    path: "/students",
    name: "studentListView",
    component: StudentListView,
  },
  {
    path: "/student/add",
    name: "studentAddView",
    component: StudentAddView,
  },
  {
    path: "/student/:id/edit",
    name: "studentEditView",
    component: StudentEditView,
  },
  {
    path: "/password",
    name: "passwordView",
    component: PasswordView,
  },
  {
    path: "/file/:id/histories",
    name: "historyView",
    component: HistoryView,
  },
  {
    path: "/coco-study",
    name: "cocoStudyView",
    component: CocoStudyView,
  },
  {
    path: "/coco-study/teacher",
    name: "teacherCocoStudyView",
    component: TeacherCocoStudyView,
  },
  {
    path: "/coco-study/material/:id",
    name: "cocoStudyMaterialView",
    component: CocoStudyMaterialView,
  },
  {
    path: "/materials",
    name: "materialListView",
    component: MaterialListView,
  },
  // LINE通知
  {
    path: "/notify",
    name: "notify",
    component: NotifyView,
  },
  {
    path: "/notify/templates",
    name: "notifyTemplates",
    component: NotifyTemplatesView,
  },
  {
    path: "/notify/groups",
    name: "notifyGroups",
    component: NotifyGroupsView,
  },
  // LINEアカウント連携
  {
    path: "/link/sibling/:nonce/login",
    name: "LineLinkSiblingLogin",
    component: LineLinkSiblingLoginView,
  },
  {
    path: "/link/sibling/:nonce",
    name: "LineLinkSibling",
    component: LineLinkSiblingView,
  },
  {
    path: "/link/:linkToken/login",
    name: "LineLinkLogin",
    component: LineLinkLoginView,
  },
  {
    path: "/link/:linkToken",
    name: "LineLink",
    component: LineLinkView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
